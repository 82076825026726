var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "app-dashboard-overview", attrs: { cols: "12" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "py-0": "" } },
                [
                  _c("header", { staticClass: "app-welcome-message" }, [
                    _c("p", [
                      _vm._v("Deze applicatie beheert de pandenlijst."),
                    ]),
                  ]),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _vm._v(
                          " In de pandenlijst worden verschillende objecten bij elkaar gebracht zodat deze objecten gezamelijk een pand vormen."
                        ),
                        _c("br"),
                        _vm._v(
                          " Een pand kan verschillende gebruikers hebben. Deze gebruikers kunnen vanuit een ander perspectief naar een pand kijken. In onderstaand figuur zijn de diverse hoofdobjecten uitgezet."
                        ),
                        _c("br"),
                        _c("br"),
                        _c("img", {
                          attrs: { src: require("@/assets/img/datamodel.svg") },
                        }),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " De pandenlijst heeft twee belangrijke ingangen van waaruit de objecten benaderd kunnen worden. "
                        ),
                        _c("ol", [
                          _c("li", [
                            _vm._v(
                              " Locaties, aan de rechterkant. Een locatie kan van alles zijn. Een pand, een ruimte in een pand, een aantal samengestelde ruimtes bv een verdieping. Maar een locatie kan ook een heel winkelcentrum zijn of alleen maar een bemeterde lantaarnpaal of een dak met zonnepanelen. De locaties zijn onderling verbonden zodat elke locaties weer uit sublocaties kan bestaan. "
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              " Partij, aan de linkerkant. Dit is een project, bedrijf, gebruiker e.d. Een eenheid die iets wil weten van een locatie. Ook hierin zit een gelaagdheid, een partij kan onderdeel zijn van een andere partij. "
                            ),
                          ]),
                        ]),
                        _vm._v(" Daartussen zitten nog twee andere objecten "),
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              " Adresseerbaar object. Een locatie heeft een aantal kenmerken. Maar er zijn ook kenmerken waar meerdere varianten van kunnen zijn. Deze zijn ondergebracht in de 'Adresseerbare objecten'. Een locatie kan bijvoorbeeld bekend zijn onder meerdere adressen. Of een locatie kan voor verschillende doeleinden verschillende bezettingsgraden hebben. "
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              " De Partij-Locatie relatie. Deze vormt de link tussen de de gebruiker en de locatie. Hiermee wordt aangegeven vanuit welk perspectief (en eventueel met welke rechten) de partij naar een adreseerbaar object (en dus indirect de locatie) kijkt. "
                            ),
                          ]),
                        ]),
                        _c("br"),
                      ]),
                    ],
                    1
                  ),
                  _setup.version
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _vm._v(
                              " Applicatie versie: " +
                                _vm._s(_setup.version) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }